#clientTablePage {

    #imgHeader {

        #pole,
        #leclerc {
            height: 10vh;
        }

    }

    .bg-img {
        height: 100vh;
        width: 100vw;
        overflow: hidden;
        position: fixed;
        top: 0;
        left: 0;
        z-index: -1;
        filter: brightness(50%);
        -webkit-filter: brightness(50%);

    }
}


// Landing Page
#landingPage {
    #sidebar {
        height: 100%;

        @media screen and (max-width: 1200px) {
            position: static;
            height: auto;
        }
    }

    #landing-bg-img {
        position: fixed;
        top: 0;
        left: 0;
        z-index: -1;
        width: 100vw;
        height: 100vh;
    }

    @media screen and (max-width: 1024px) {
        .fs-4 {
            font-size: calc(1.275rem - 0.3vw) !important;
        }

        #landing-bg-img {
            position: fixed;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            z-index: -1;
            width: auto;
            height: 100vh;
        }
    }
}

#mainBelleVie {
    background-color: #F8F9FA;

    #landing-bg-img {
        width: 100%;
        height: 100vh;
    }

    #bg-parent {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        width: 100%;
        height: 100vh;
        overflow: hidden;
    }

    #bg-parent:before {
        content: '';
        background-image: linear-gradient(to top, rgba(248, 249, 250, 255) 5%, rgba(248, 249, 250, 0) 35%);
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        width: 100%;
        height: 100vh;
    }

    .container {
        margin-top: 10vh;
    }

}

@media screen and (max-width: 992px) {

    #mainBelleVie {
        #landing-bg-img {
            width: 100%;
            min-height: 100%;
            max-height: 150%;
        }

        #bg-parent {
            z-index: 0;
            position: fixed;
            top: 0;
            width: 100%;
            height: 100vh;
            overflow: hidden;
        }

        .container {
            margin-top: 4vh;
        }
    }
}

@media screen and (max-width: 576px) {
    #mainBelleVie {

        #landing-bg-img {
            display: none;
        }

        #bg-parent {
            display: none;
        }

        .container {
            margin-top: 0;
        }
    }
}
