body * {
    -webkit-font-smoothing: subpixel-antialiased !important;
}

nav {
    .nav-link,
    .navbar-brand {
        font-size: 18px;
    }
    .brand-logo {
        width: 60px;
    }

    .nav-btn {
        font-size: 14px;
        margin: 0 10px;
        max-width: 200px;
        padding: 10px;
    }
}

/*
    // SCROLL
 */

 .scroll::-webkit-scrollbar-track
 {
     border-radius: 10px;
     background-color: $body-bg;
 }

 .scroll::-webkit-scrollbar
 {
     width: 8px;
     background-color: $body-bg;
 }

 .scroll::-webkit-scrollbar-thumb
 {
     background-color: $blue;
 }

 .table-responsive::-webkit-scrollbar
 {
    height: 8px;
    background-color: $body-bg;
 }
 .table-responsive::-webkit-scrollbar-track
 {
     background-color: $body-bg;
 }

 .table-responsive::-webkit-scrollbar-thumb
 {
     background-color: $blue;
     border-radius: 10px;
 }


#clientTablePage {

    #imgHeader {
        #pole, #leclerc {
            height: 10vh;
        }

    }

    .bg-img {
        height: 100vh;
        width: 100vw;
        overflow: hidden;
        position: fixed;
        top: 0;
        left: 0;
        z-index: -1;
        filter: brightness(50%);
        -webkit-filter: brightness(50%);

    }
}

.pointer {
    cursor: pointer;
}

select {
    overflow-y: auto;
}

@media print {
    body {
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, Helvetica, Arial, sans-serif !important;
    }
}
