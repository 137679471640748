@font-face {
    font-family: 'Raleway';
    src: url('/font/Raleway.ttf') format('truetype');
}

@font-face {
    font-family: 'Nunito';
    src: url('/font/Nunito.ttf') format('truetype');
}



// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Raleway', 'Nunito', sans-serif;
$font-size-base: 1rem;
$line-height-base: 1.6;

// Colors
$blue: #3565c3;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #F59F14;
$yellow: #F59F14;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;
$blue-custom: #3565c3;
